<!-- eslint-disable @typescript-eslint/no-unsafe-assignment -->
<script setup lang="ts">
import {
  computed, defineAsyncComponent, hydrateOnVisible,
} from 'vue';
import VHeaderGetStarted from 'UiKit/components/VHeader/VHeaderGetStarted.vue';
import { useData, useRoute } from 'vitepress';


// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VFooter = defineAsyncComponent({
  loader: () => import('UiKit/components/VFooter/VFooter.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const HelpUkraineBanner = defineAsyncComponent({
  loader: () => import('@/layouts/HelpUkraine/components/HelpUkraineBanner.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});


const ViewHome = defineAsyncComponent(() => import('@/layouts/Home/ViewHome.vue'));
const ViewGetStarted = defineAsyncComponent(() => import('@/layouts/ViewGetStarted.vue'));
const ViewResourceCenter = defineAsyncComponent(() => import('@/layouts/ViewResourceCenter.vue'));
const ViewResourceCenterTags = defineAsyncComponent(() => import('@/layouts/ViewResourceCenterTags.vue'));
const ViewResourceCenterPost = defineAsyncComponent(() => import('@/layouts/ViewResourceCenterPost.vue'));
const ViewAuthorPage = defineAsyncComponent(() => import('@/layouts/ViewAuthorPage.vue'));
const ViewProducts = defineAsyncComponent(() => import('@/layouts/ViewProducts.vue'));
const ViewHowWeCanAssist = defineAsyncComponent(() => import('@/layouts/ViewHowWeCanAssist.vue'));
const ViewHelpUkraine = defineAsyncComponent(() => import('@/layouts/HelpUkraine/ViewHelpUkraine.vue'));
const ViewDigitalEcosystem = defineAsyncComponent(() => import('@/layouts/ViewDigitalEcosystem.vue'));
const ViewDevelopmentStages = defineAsyncComponent(() => import('@/layouts/ViewDevelopmentStages.vue'));
const ViewDevelopment = defineAsyncComponent(() => import('@/layouts/ViewDevelopment.vue'));
const ViewCtoAsAService = defineAsyncComponent(() => import('@/layouts/ViewCtoAsAService.vue'));
const ViewAboutUs = defineAsyncComponent(() => import('@/layouts/ViewAboutUs.vue'));
const ViewCaseStudies = defineAsyncComponent(() => import('@/layouts/ViewCaseStudies.vue'));
const ViewCaseStudiesSingle = defineAsyncComponent(() => import('@/layouts/ViewCaseStudiesSingle.vue'));
const ViewCareersSingle = defineAsyncComponent(() => import('@/layouts/ViewCareerSingle.vue'));
const ViewCareers = defineAsyncComponent(() => import('@/layouts/ViewCareers.vue'));
const ViewUIKitSingle = defineAsyncComponent(() => import('@/layouts/ViewUIKitSingle.vue'));
const ViewWikiSingle = defineAsyncComponent(() => import('@/layouts/ViewWikiSingle.vue'));
const ViewErrors = defineAsyncComponent(() => import('UiKit/components/VErrorPage/VErrorPageNotFound404.vue'));

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VToaster = defineAsyncComponent(() => import(
  'UiKit/components/Base/VToaster/VToaster.vue'
));

const { page, frontmatter } = useData();
const route = useRoute();

const showFooter = computed(() => {
  const routes = ['/get-started'];
  return !routes.includes(String(route.path));
});
</script>


<template>
  <div class="AppLayoutDefault app-layout-default">
    <VHeaderGetStarted />

    <main class="app-layout-default__main is--markdown">
      <ViewErrors
        v-if="page.isNotFound"
      />
      <ViewHome v-else-if="frontmatter.layout === 'home'" />
      <ViewGetStarted
        v-else-if="frontmatter.layout === 'get-started'"
      />
      <ViewResourceCenter
        v-else-if="frontmatter.layout === 'resource-center'"
      />
      <ViewResourceCenterTags
        v-else-if="frontmatter.layout === 'resource-center-tags'"
      />
      <ViewResourceCenterPost
        v-else-if="frontmatter.layout === 'resource-center-single'"
      />
      <ViewAuthorPage
        v-else-if="frontmatter.layout === 'resource-center-author'"
      />
      <ViewProducts
        v-else-if="frontmatter.layout === 'products'"
      />
      <ViewHowWeCanAssist
        v-else-if="frontmatter.layout === 'how-can-we-assist'"
      />
      <ViewHelpUkraine
        v-else-if="frontmatter.layout === 'help-ukraine'"
      />
      <ViewDigitalEcosystem
        v-else-if="frontmatter.layout === 'digital-ecosystem'"
      />
      <ViewDevelopmentStages
        v-else-if="frontmatter.layout === 'development-stages'"
      />
      <ViewDevelopment
        v-else-if="frontmatter.layout === 'development-single'"
      />
      <ViewCtoAsAService
        v-else-if="frontmatter.layout === 'cto-as-a-service'"
      />
      <ViewAboutUs
        v-else-if="frontmatter.layout === 'about-us'"
      />
      <ViewCaseStudiesSingle
        v-else-if="frontmatter.layout === 'case-studies-single'"
      />
      <ViewCaseStudies
        v-else-if="frontmatter.layout === 'case-studies'"
      />
      <ViewCareersSingle
        v-else-if="frontmatter.layout === 'careers-single'"
      />
      <ViewCareers
        v-else-if="frontmatter.layout === 'careers'"
      />
      <ViewWikiSingle
        v-else-if="frontmatter.layout === 'wiki-single'"
      />
      <ViewUIKitSingle
        v-else-if="frontmatter.layout === 'uikit-single'"
      />
      <div
        v-else
        class="is--content is--container is--page"
      >
        <Content />
        <!-- if we use somewhere Content it is mandatory to wrap it in container with class 'is--markdown'-->
      </div>
    </main>

    <template v-if="showFooter">
      <HelpUkraineBanner />
      <footer class="app-layout-default__footer">
        <VFooter />
      </footer>
    </template>

    <Notifications
      position="top right"
      group="transaction"
    >
      <template #body="props">
        <VToaster
          v-bind="props.item"
          :duration="props.item.data.duration"
          @close="props.close"
        />
      </template>
    </Notifications>
  </div>
</template>


<style lang="scss">
.app-layout-default {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;

  &__main {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    background: $white;
    min-height: 50vh;
    position: relative;
  }

  .is--content {
    width: 100%;
  }

  &__footer {
    width: 100%;
    margin-top: 0 !important;
  }
}
</style>
