import '@/assets/index.scss';
import type { Theme } from 'vitepress';
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import { createPinia } from 'pinia';
import VueNotification from '@kyvg/vue3-notification';
import { defineAsyncComponent, hydrateOnVisible } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VTooltip = defineAsyncComponent({
  loader: () => import('UiKit/components/VTooltip.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VButton = defineAsyncComponent({
  loader: () => import('UiKit/components/Base/VButton/VButton.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VBadge = defineAsyncComponent({
  loader: () => import('UiKit/components/Base/VBadge/VBadge.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VHighlight = defineAsyncComponent({
  loader: () => import('UiKit/components/VAds/VHighlight.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSectionCaseStudiesMain = defineAsyncComponent({
  loader: () => import('UiKit/components/VSection/VSectionCaseStudiesMain.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSliderSectionCardPrimary = defineAsyncComponent({
  loader: () => import('UiKit/components/VSlider/VSliderSectionCardPrimary.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VSlider = defineAsyncComponent({
  loader: () => import('UiKit/components/VSlider/VSlider.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VWeHelp = defineAsyncComponent({
  loader: () => import('UiKit/components/VAds/VWeHelp.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const VCardCheckmarked = defineAsyncComponent({
  loader: () => import('UiKit/components/VCard/VCardCheckmarked.vue'),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  hydrate: hydrateOnVisible(),
});

export default {
  Layout: AppLayoutDefault,
  enhanceApp({ app, router, siteData }) {
    const pinia = createPinia();
    app.use(pinia)
    app.use(VueNotification)
    app.component('VTooltip', VTooltip)
    app.component('VButton', VButton)
    app.component('VBadge', VBadge)
    app.component('VHighlight', VHighlight)
    app.component('VSectionCaseStudiesMain', VSectionCaseStudiesMain)
    app.component('VSliderSectionCardPrimary', VSliderSectionCardPrimary)
    app.component('VSlider', VSlider)
    app.component('VWeHelp', VWeHelp)
    app.component('VCardCheckmarked', VCardCheckmarked)
  }
} satisfies Theme
